import React, { useState } from 'react'
import '../styles/blog.scss'
import Layout from "../components/layout";
import Seo from "../components/SEO/SEO"
import { useStaticQuery, graphql } from 'gatsby'
import TarjetaPost from '../components/tarjetaPost'
import HeaderPaginas from '../components/headerPaginas'





export const query = graphql`
query {
  allStrapiPostSecurity(sort: {order: DESC, fields: published_at}) {
    edges {
      node {
        titulo
          categorias {
            nombre
          }
          published_at
          overlay_blanco
          extracto
          slug
          published_at
          imagen_destacada {
            url
            formats {
              small {
                url
              }
            }
          }
      }
    }
  }
  allStrapiCategorias {
		edges {
      node {
        nombre
        post_securities {
          id
        }
      }
    }
  }
}
`


export default function PaginaBlog(props) {

  const data = useStaticQuery(query);
  const listaCats = data.allStrapiCategorias.edges

  const isotope = React.useRef()

  const [filterKey, setFilterKey] = useState('*')
  const [select, setSelect] = useState('*')
  // initialize an Isotope object with configs


  React.useEffect(() => {
    const Isotope = require("isotope-layout/js/isotope");
    require("isotope-packery/packery-mode")
    isotope.current = new Isotope('.contenedor', {
      itemSelector: '.post',
      layoutMode: 'packery',
      // packery: {
      //   columnWidth: '.post'
      // }
    })
    // cleanup
    return () => isotope.current.destroy()
  }, [])

  React.useEffect(() => {
    filterKey === '*'
      ? isotope.current.arrange({ filter: `*:not(.vacio)` })
      : isotope.current.arrange({ filter: `.${filterKey}` })
  }, [filterKey])


  // FUNCIÖN PARA FILTRAR LOS POSTS
  const handleFilterKeyChange = key => () => {
    const botones = document.querySelectorAll('.boton-filtro');
    let numposts = ""

    botones.forEach(boton => {
      if (boton.id !== key) {
        boton.classList.remove('activo')
      } else {
        boton.classList.add('activo')
      }
    });

    if (key !== "*") {
      numposts = document.querySelectorAll(`.${key}`).length
      if (numposts === 0) {
        // mensaje.style.display = "block"
        setFilterKey("vacio")
      } else {
        // mensaje.style.display = "none"
        setFilterKey(key)
      }
    } else {
      setFilterKey(key)
    }
  }

  function handleSelect(e) {
    
    let numposts = ""

    if (e.target.value !== "Todos") {
      numposts = document.querySelectorAll(`.${e.target.value}`).length
      if (numposts === 0) {
        // mensaje.style.display = "block"
        setFilterKey("vacio")
      } else {
        // mensaje.style.display = "none"
        setFilterKey(e.target.value)
      }
    } else {
      setFilterKey("*")
    }
    setSelect(e.target.value)
  }

  return (
    <Layout>
      <Seo pathname={props.location.pathname} />
      <HeaderPaginas titulo="Posts supervitaminados" imagen="/img/cabeceras/BlogBullHostslider.jpg" />
      <div>
        {/* Filtro de posts */}
        <div className="filtro-posts-movil">
          <select name="filtro-cat" id="filtro-movil" value={select} onChange={handleSelect}>
            <option value="Todos" selected>Todos</option>
            {listaCats.map(({ node }, index) => {
            if (node.post_securities.length === 0) {
              return
            } else {
              return (
                <option
                value={node.nombre}
                key={index}
                id={node.nombre}
                >
                {node.nombre}
              </option>
            )
            }
            })}
          </select>
        </div>
        <div className="filtro-posts">
          <button
            onClick={handleFilterKeyChange("*")}
            // onClick={(e) => handleClick("*")}
            id="*"
            className="boton-filtro activo"
          >
            Todos
          </button>
          {listaCats.map(({ node }, index) => {
            if (node.post_securities.length === 0) {
              return
            } else {
            return (
              <button
                key={index}
                id={node.nombre}
                onClick={handleFilterKeyChange(`${node.nombre}`)}
                className={`boton-filtro`}
              >
                {node.nombre}
              </button>
            )
            }
          })}
        </div>
        {/* FIN filtro de posts */}
        <div id="contenedor-posts" className="contenedor">
          {data.allStrapiPostSecurity.edges.map(({ node }, index) => {
            return <TarjetaPost clave={index} post={node} />
          })}
          <div id="mensaje-vacio" className="vacio post">
            <p>No hay entradas en esta categoría</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}