import { Link } from "gatsby"
import React from "react"
import "../styles/tarjetaPost.scss"

export default function TarjetaPost(props) {
  let datos = props.post
  let key = props.clave + 1
  let categorias = ""
  let urlFoto = datos.imagen_destacada.formats.small.url
    ? datos.imagen_destacada.formats.small.url
    : datos.imagen_destacada.url
  let urlDestacada = `${process.env.API_URL}${urlFoto}`

  if (key < 10) {
    key = "0" + String(key)
  }

  function convertirFecha(fecha) {
    return new Date(fecha).toLocaleDateString("es", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
  }

  categorias =
    categorias +
    datos.categorias
      .map(cat => {
        return cat.nombre
      })
      .join(" ")

  return (
    <>
      <article className={`tarjeta-entrada post ${categorias}`}>
        <div
          className="fondo-post"
          style={{ backgroundImage: `url(${urlDestacada})` }}
        >
          <div
            className={`overlay ${datos.overlay_blanco ? "blanco" : "negro"}`}
          ></div>
        </div>
        <div className="datos-post">
          <div
            className={`categorias ${
              datos.overlay_blanco ? "blanco" : "negro"
            }`}
          >
            {datos.categorias.map((value, index) => {
              if (value.nombre !== "Destacado" && value.nombre !== "Blanco") {
                return <span key={index}>{value.nombre}</span>
              }
              return ""
            })}
          </div>
          <div
            className={`textos ${datos.overlay_blanco ? "blanco" : "negro"}`}
          >
            <span className="fecha">{convertirFecha(datos.published_at)}</span>
            <h2>
              <Link className="titulo" to={`/blog/${datos.slug}/`}>
                {datos.titulo}
              </Link>
            </h2>
            {categorias.includes("Destacado") ? (
              <p className="extracto">{datos.extracto}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </article>
    </>
  )
}
